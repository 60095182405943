//import "../stylesheets/odometer-theme-default.scss";
//import "../stylesheets/odometer.scss";


//import * as Odometer from "./odometer";

import Odometer from "./odometer";
window.Odometer = Odometer;


$(document).on('turbolinks:load', function() {

const createOdometer = (el, value) => {
  const odometer = new Odometer({
    el: el,
    value: 0,
  });

  let hasRun = false;

  const options = {
    threshold: [0, 0.9],
  };

  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (!hasRun) {
          odometer.update(value);
          hasRun = true;
        }
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(el);
};


const candidatesOdometer = document.querySelector(".candidates-odometer");

if (candidatesOdometer){

  let candidatesNumber = parseInt(candidatesOdometer.getAttribute("data-count"));
  createOdometer(candidatesOdometer, candidatesNumber);
}

const jobsOdometer = document.querySelector(".jobs-odometer");

if (jobsOdometer){

  let jobsNumber = parseInt(jobsOdometer.getAttribute("data-count"));
  createOdometer(jobsOdometer, jobsNumber);
}

const companiesOdometer = document.querySelector(".companies-odometer");

if (companiesOdometer){

  let companiesNumber = parseInt(companiesOdometer.getAttribute("data-count"));
  createOdometer(companiesOdometer,companiesNumber);
}

});